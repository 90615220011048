import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../../../Context/MyContext';

import styles from '../Menu.module.css';

import api from '../../../Api/apiAxios';

function MenuDefault() {
    const navigate = useNavigate();
    const { logoutApp } = React.useContext(MyContext);
    const [username, setUsername] = useState('');

    useEffect(() => {
        (async () => {
            const userId = window.localStorage.getItem('idUsuario');
            try {
                const response = await api.get(`/Usuario/${userId}`);
                setUsername(response.data.nome);
            } catch (e) {               
                if (e.response.status !== 401) {console.log(e)};
            }
            
        })();
    }, [])
  
    return (
        <Navbar collapseOnSelect expand="lg" style={{backgroundColor: '#132350'}} variant="dark" >
        <Container>
            <Navbar.Brand style={{cursor: 'pointer'}} onClick={()=>navigate("/")}>BmaPortal</Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Nav className={styles.nav_container}>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className={styles.menu_items}>
                        <NavDropdown title="Cadastros" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/usuarios")}>Usuarios</NavDropdown.Item>                    
                            <NavDropdown.Item onClick={()=>navigate("/clientes")}>Clientes</NavDropdown.Item>                   
                            <NavDropdown.Item onClick={()=>navigate("/distribuidores")}>Distribuidores</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/contratos")}>Contratos</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/servicos")}>Serviços</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/versoes")}>Versões</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/consultaclientes")}>Consulta Clientes (Geral)</NavDropdown.Item>                   
                        </NavDropdown>

                        <NavDropdown title="Pedidos" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/pedidosponto")}>BmaPonto</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/pedidosacesso")}>BmaAcesso</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/pedidosfolha")}>BmaFolha</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title="Licenças" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesponto")}>BmaPonto</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesacesso")}>BmaAcesso</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesfolha")}>BmaFolha</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/clienteversoes")}>Clientes x Sistemas</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/personalizacoesplanovertical")}>Consulta por Plano / Vertical</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link onClick={()=>navigate("/geracaofaturamento")} style={{marginRight: "15px", minWidth: '160px'}}>Geração Faturamento</Nav.Link>

                        <NavDropdown title="Relatórios" id="collasible-nav-dropdown" style={{marginRight: "15px"}}>
                            <NavDropdown.Item onClick={()=>navigate("/relatoriopedidos")}>Pedidos / OS</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamento")}>Parcial Mês - Faturamento (Totais)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatorioparcialfaturamentorevenda")}>Parcial Mês - Faturamento (Por Revenda)</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/relatoriofaturamento")}>Faturamento por Período</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>navigate("/desempenho")}>Desempenho por Revenda</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/contratoscancelados")}>Contratos Cancelados</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>navigate("/dashboard")}>Dashboard</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Navbar.Collapse>
            </Nav>
            <div className={styles.logout_container}>
                <Navbar.Brand>Olá {username}!</Navbar.Brand>
                <Nav.Link onClick={logoutApp} style={{marginRight: "15px"}}>Sair</Nav.Link>
            </div>
        </Container>
        </Navbar>

    );
}

export default MenuDefault;