import { HashRouter } from "react-router-dom";
import { MyStorage } from "./Context/MyContext";
import Menu from "./Forms/Menu";
import { Router } from "./Components/Router";
import { DashboardData } from "./Context/DashboardAlternadoContext";

function App() {
  return (
    <HashRouter>
      <MyStorage>
          <DashboardData>
            <Menu />
            <Router />
          </DashboardData>
      </MyStorage>   
    </HashRouter>    
  );
}

export default App;
