import React from "react";
import { useNavigate } from "react-router-dom";

export const MyContext = React.createContext();

export const MyStorage = ({ children }) => {
    const [token, setToken] = React.useState(null);
    const [tokenTimeout, setTokenTimeout] = React.useState(null);
    const [usuario, setUsuario] = React.useState('');   
    const [mensagemGlobal, setMensagemGlobal]  = React.useState('');
    const [tipoMensagemGlobal, setTipoMensagemGlobal] = React.useState('');
    const [qtdeAcessos, setQtdeAcessos] = React.useState(0);    
    const [listaGlobalClientes, setListaGlobalClientes] = React.useState(null);
    const [listaGlobalContratos, setListaGlobalContratos] = React.useState(null);
    const [listaGlobalLicencasBmaPonto, setListaGlobalLicencasBmaPonto] = React.useState(null);
    const [pesquisaGlobalLicencasBmaPonto, setPesquisaGlobalLicencasBmaPonto] = React.useState(null);
    const [listaGlobalLicencasBmaAcesso, setListaGlobalLicencasBmaAcesso] = React.useState(null);
    const [pesquisaGlobalLicencasBmaAcesso, setPesquisaGlobalLicencasBmaAcesso] = React.useState(null);
    const [distribuidorIdGlobal, setDistribuidorIdGlobal] = React.useState('');

    const itensPorPagina = 20;

    const navigate = useNavigate();

    const clearGlobalVariables = React.useCallback(async function () {
        setUsuario('');
        setListaGlobalClientes(null);
        setListaGlobalContratos(null);
        setListaGlobalLicencasBmaPonto(null);
        setPesquisaGlobalLicencasBmaPonto(null);
        setListaGlobalLicencasBmaAcesso(null);
        setPesquisaGlobalLicencasBmaAcesso(null);
        setToken(null);
        setQtdeAcessos(0);
        setDistribuidorIdGlobal('');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('nivelUsuario');
        window.localStorage.removeItem('idUsuario');
        window.localStorage.removeItem('distribuidorId');
    }, [navigate])

    const logoutApp = React.useCallback(async function () {
        clearGlobalVariables();
        setTokenTimeout(false);
        navigate('/login');
    }, [navigate])

    const timeoutLogout = React.useCallback(async function () {
        clearGlobalVariables();
        setTokenTimeout(true);
        navigate('/login');
    }, [navigate])

    return (
        <MyContext.Provider 
            value={{ 
                token, 
                setToken, 
                usuario, 
                setUsuario, 
                mensagemGlobal, 
                setMensagemGlobal, 
                tipoMensagemGlobal, 
                setTipoMensagemGlobal,
                listaGlobalClientes, 
                setListaGlobalClientes,
                listaGlobalContratos, 
                setListaGlobalContratos,
                listaGlobalLicencasBmaPonto, 
                setListaGlobalLicencasBmaPonto,
                pesquisaGlobalLicencasBmaPonto, 
                setPesquisaGlobalLicencasBmaPonto,
                listaGlobalLicencasBmaAcesso, 
                setListaGlobalLicencasBmaAcesso,
                pesquisaGlobalLicencasBmaAcesso, 
                setPesquisaGlobalLicencasBmaAcesso,
                qtdeAcessos, 
                setQtdeAcessos,
                distribuidorIdGlobal, 
                setDistribuidorIdGlobal, 
                itensPorPagina,
                logoutApp, 
                tokenTimeout, 
                setTokenTimeout, 
                timeoutLogout 
            }}
        >
            {children}
        </MyContext.Provider>
    
    )}
    