import React from "react";
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Lightning, Pencil } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import { MyContext } from "../../Context/MyContext";
import HeaderVersoes from "../../Components/HeaderVersoes";
import Loading from "../../Components/Loading";
import Mensagem from "../../Components/Mensagem";
import styles from "./VersoesIndex.module.css";
import api from "../../Api/apiAxios";

const VersoesIndex = () => {
  const navigate = useNavigate();

  const nivelUsuario = window.localStorage.getItem('nivelUsuario');

  const [pesquisa, setPesquisa] = React.useState('');
  const [mensagem, setMensagem] = React.useState(null);
  const [versoes, setVersoes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [tipoPesquisa, setTipoPesquisa] = React.useState('*');
  const [botaoAdicionar, setBotaoAdicionar] = React.useState('');
  const [legendaLoad, setLegendaLoad] = React.useState('');

  const { mensagemGlobal, setMensagemGlobal, tipoMensagemGlobal } = React.useContext(MyContext);

  const ordenarLista = (lista, index) => {
    return lista.sort((a, b) => b[`${index}`] - a[`${index}`])
  }

  React.useEffect(() => {   
    if  (nivelUsuario === "2" || nivelUsuario === "4") {
      setBotaoAdicionar("hide");
    } else {
      setBotaoAdicionar(' Nova Versão');
    }

    async function pesquisar() { 
      setLegendaLoad('');
      setLoading(true);                  
      const response = await api.get('/Versao');
      setVersoes(ordenarLista(response.data, 'id'));          

      setLoading(false);       
    }
    
    pesquisar();            
  }, []);

  function pesquisarClick(event) { 
    event.preventDefault();     
    (async () => {
      try {
        if  (pesquisa.length === 0) {           
          if  (tipoPesquisa === "ponto" || tipoPesquisa === "folha" || tipoPesquisa === "acesso") {
            const pesq = 'sistema';
            const response = await api.get(`/Versao/parametros-consulta?tipo=${tipoPesquisa}&args=${pesq}`);  

            setVersoes(ordenarLista(response.data, 'id'));              
          } else {
            const pesq = '*';
            const response = await api.get(`/Versao/parametros-consulta?tipo=${tipoPesquisa}&args=${pesq}`);  

            setVersoes(ordenarLista(response.data, 'id'));              
          }
        } else {
          const tipo = 'versao';
          const response = await api.get(`/Versao/parametros-consulta?tipo=${tipo}&args=${pesquisa}`);  

          setVersoes(ordenarLista(response.data, 'id'));              
        }

        setPesquisa('');
        setLoading(false);              
      } catch (error) {
        console.log(error);
      }
    }
    )();
  }

  function adicionarClick(event) {
    event.preventDefault();
    navigate("/novaversao");
  }

  function editarClick(versao) {              
    navigate(`/novaversao/${versao.id}`);
  }

  async function atualizarPontoClick(tipoAtualizacao) {  
    try {
      const tipoLegenda = tipoAtualizacao === 0 ? 'Desktop' : 'Saas'
      setLegendaLoad('Atualizando Licenças de Ponto ' + tipoLegenda);
      setLoading(true);  

      const sistema = 1;
      const ret = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}`);   
      const numeroVersaoEditada = ret.data.numeroVersao.toString();
      const iniVer = numeroVersaoEditada.substring(2,0);
      const fimVer = numeroVersaoEditada.substring(2);
      const versaoEditada = iniVer + "." + fimVer;                                      
      const releaseEditado = ret.data.release;
  
      const response = await api.put(`/LicencaPonto/atualizar-versao-licencas?versao=${versaoEditada}&release=${releaseEditado}&tipo=${tipoAtualizacao}`);
      if (response.data) setMensagem(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setLegendaLoad('');
    } 
  }

  async function atualizarAcessoClick(tipoVertical) {
    try {
      let tipoLegenda = '';

      switch (tipoVertical) {
        case 1:
          tipoLegenda = 'Empresa';
          break;
        case 2:
          tipoLegenda = 'Refeitório';
          break;
        case 3:
          tipoLegenda = 'Academia';
          break;
        case 4:
          tipoLegenda = 'Condomínio';
          break;
        case 5:
          tipoLegenda = 'Hospital';
          break;
        case 6:
          tipoLegenda = 'Escola';
          break;
        default:
          break;
      }

      setLegendaLoad('Atualizando Licenças de Acesso ' + tipoLegenda);
      setLoading(true);  

      const sistema = 2;
      const ret = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}&tipoVertical=${tipoVertical}`);   
      const numeroVersaoEditada = ret.data.numeroVersao.toString();
      const iniVer = numeroVersaoEditada.substring(1,0);
      const fimVer = numeroVersaoEditada.substring(2,1);
      const versaoEditada = iniVer + "." + fimVer;                                      
      const releaseEditado = ret.data.release;

      const response = await api.put(`/LicencaAcesso/atualizar-versao-licencas?versao=${versaoEditada}&release=${releaseEditado}&tipoVertical=${tipoVertical}`);
      if (response.data) setMensagem("Licenças atualizadas. Para mais informações, vide PDF.");

      if (response.data) {
        const response1 = await api.get(`/LicencaAcesso/retornar-pdf?fileName=${response.data}`, { responseType: 'blob' });
        var pdfBlob = new Blob([response1.data], { type: 'application/pdf'} );
        var url = window.URL.createObjectURL(pdfBlob)
        window.open(url);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setLegendaLoad('');
    }
  }

  async function atualizarFolhaClick() { 
    try {
      setLegendaLoad('Atualizando Licenças de Folha');
      setLoading(true);

      const sistema = 3;
      const ret = await api.get(`/Versao/retornar-versao-atual?tipoSistema=${sistema}`);   
      const numeroVersaoEditada = ret.data.numeroVersao.toString();
      const iniVer = numeroVersaoEditada.substring(2,0);
      const fimVer = numeroVersaoEditada.substring(2);
      const versaoEditada = iniVer + "." + fimVer;                                      
      const releaseEditado = ret.data.release;

      const response = await api.put(`/LicencaFolha/atualizar-versao-licencas?versao=${versaoEditada}&release=${releaseEditado}`);
      if (response.data) setMensagem(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setLegendaLoad('');
    }
  }

  if (loading) return <Loading legenda={legendaLoad}/>

  return (
    <section>
      {mensagem && <Mensagem mensagem={mensagem} setMensagem={setMensagem} variant="warning" /> }
      {mensagemGlobal && <Mensagem mensagem={mensagemGlobal} setMensagem={setMensagemGlobal} variant={tipoMensagemGlobal} /> }                                      

      <HeaderVersoes 
        titulo={"Versões"} 
        textoBotao={botaoAdicionar} 
        pesquisa={pesquisa} 
        setPesquisa={setPesquisa}
        tipoPesquisa={tipoPesquisa} 
        setTipoPesquisa={setTipoPesquisa}
        pesquisarClick={pesquisarClick} 
        adicionarClick={adicionarClick}
      />

      <div className={styles.botoes}>
        <div className={styles.barra}>
          <Button onClick={() => atualizarPontoClick(0)}disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Ponto Desktop
          </Button>   

          <Button onClick={() => atualizarPontoClick(1)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Ponto Saas
          </Button>   

          <Button onClick={() => atualizarFolhaClick()} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Folha
          </Button>   

          <Button onClick={() => atualizarAcessoClick(1)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Acesso Empresa
          </Button>   

          <Button onClick={() => atualizarAcessoClick(2)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Refeitório
          </Button>   

          <Button onClick={() => atualizarAcessoClick(3)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Academia
          </Button>   

          <Button onClick={() => atualizarAcessoClick(4)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Condomínio
          </Button>   

          <Button onClick={() => atualizarAcessoClick(5)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Hospital
          </Button>   

          <Button onClick={() => atualizarAcessoClick(6)} disabled={nivelUsuario !== "1"} variant="outline-primary">
            <Lightning size={18}/>
            Escola
          </Button>                     
        </div>
      </div>

      <br />
      <br />

      <Table striped hover responsive>
        <thead className={styles.thead}>
          <tr className={styles.trHead}>
            <th />
            <th />
            <th className={styles.th}>Sistema</th>                        
            <th className={styles.th}>Versão</th>
            <th className={styles.th}>Release</th>
            <th className={styles.th}>Data Lib.</th>
            <th className={styles.th}>Vertical</th>
          </tr>
        </thead>

        <tbody className={styles.tbody}>
          {versoes.map(versao => 
            <tr key={versao.id} className={styles.trBody}>  
              <td />
              <td />                                  
              {versao.tipoSistema === 1 && <td className={styles.td} style={{color: "navy", fontWeight: "bold"}}>BmaPonto</td>}
              {versao.tipoSistema === 2 && <td className={styles.td} style={{color: "seaGreen", fontWeight: "bold"}}>BmaAcesso</td>}
              {versao.tipoSistema === 3 && <td className={styles.td} style={{color: "blue", fontWeight: "bold"}}>BmaFolha</td>}

              <td className={styles.td}>{versao.numeroVersao}</td>          
              <td className={styles.td}>{versao.release}</td>

              <td className={styles.td}> 
                {versao.dataLiberacao.substring(8,10)}/{versao.dataLiberacao.substring(5,7)}/{versao.dataLiberacao.substring(0,4)}                               
              </td>

              {versao.tipoVertical === 1 && <td className={styles.td}>Empresa</td>}
              {versao.tipoVertical === 2 && <td className={styles.td}>Refeitório</td>}
              {versao.tipoVertical === 3 && <td className={styles.td}>Academia</td>}
              {versao.tipoVertical === 4 && <td className={styles.td}>Condomínio</td>}
              {versao.tipoVertical === 5 && <td className={styles.td}>Hospital</td>}
              {versao.tipoVertical === 6 && <td className={styles.td}>Escola</td>}
              {(versao.tipoVertical === null || versao.tipoVertical === "0" ||  versao.tipoVertical === "") && <td className={styles.td}>* * * * * *</td>}

              <td>
                <OverlayTrigger overlay={<Tooltip id="btn-editar">Editar</Tooltip> }> 
                  <Button onClick={() => editarClick(versao)} disabled={nivelUsuario !== "1"} variant="outline-warning">
                    <Pencil size={18}/>
                  </Button>   
                </OverlayTrigger>                                                         
              </td>                        
            </tr>
           )}
        </tbody>
      </Table>
    </section>
  )
}

export default VersoesIndex;