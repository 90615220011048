import React, { useEffect } from "react";
import api from "../../Api/apiAxios";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../Context/MyContext";
import { Button } from "react-bootstrap";
import { Globe2 } from "react-bootstrap-icons";
import styles from "./Login.module.css";
import imagem2 from "../../Img/FundoLogin.png";

import Mensagem from "../../Components/Mensagem";

const Login = () => {
  
    const dadosContext = React.useContext(MyContext);
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [erro, setErro] = React.useState("");
    const [tipoMensagem, setTipoMensagem] = React.useState("");    
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (dadosContext.tokenTimeout === true) {
            setErro("Token expirado, faça o login novamente");
            setTipoMensagem("danger");
            dadosContext.setTokenTimeout('');
        }
    }, [dadosContext])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            validarLogin(event);
        }    
    }

    const validarLogin = async (ev) => {
        ev.preventDefault();

        if (email === "") {
            setErro("Informe seu email.");
            setTipoMensagem("danger");
            return
        } else if (password === "") {
            setErro("Informe sua senha.");
            setTipoMensagem("danger");
            return
        } 

        try {        
            setLoading(true);
            setErro('');

            const response = await api.get('Usuario/' + email + "/" + password);  

            if  (response.data.id === 0)
            {
                setErro("Usuário não encontrado, verifique suas informações!");
                setTipoMensagem("danger");    
                setEmail("")       
                setPassword("") 
                setLoading(false); 
                dadosContext.setUsuario("");
                dadosContext.setToken("");        
    
            }
            else
            {
                setErro("");
                setLoading(false);
                dadosContext.setUsuario(response.data);           

                const dataAtual = new Date();
                let regUsuario = {
                    Id: 0,
                    DistribuidorId: 0,
                    Nome: ' ',
                    Email: email,    
                    Senha: password,
                    Telefone: ' ',    
                    Celular: ' ',
                    Status: 0,                
                    TipoUsuario: 0,
                    DataHoraCadastro: dataAtual,
                    DataHoraUltimoAcesso: dataAtual, 
                    EnviarEmailCredenciais: 0,
                    EnviarEmailFaturamento: 0,          
                }         

                const resp = await api.post("Token/",
                    JSON.stringify(regUsuario),
                    {
                        headers: { 'Content-Type': 'application/json'},
                        withCredentials: false           
                    }
                )
                window.localStorage.setItem('token', resp.data);  
                window.localStorage.setItem('nivelUsuario', response.data.tipoUsuario);   
                window.localStorage.setItem('distribuidorId', response.data.distribuidorId);
                window.localStorage.setItem('idUsuario', response.data.id);
                navigate("/");
            }
        } catch (error) {               
            setErro("Não foi possível conectar ao servidor... Verifique a Conexão !");
            setTipoMensagem("danger");
            setLoading(false);
        }
    }


    return(
        <form className={styles.form}>
            {erro && <Mensagem mensagem={erro} setMensagem={setErro} variant={tipoMensagem} width="25rem" />}

            <div className={styles.principal}>           
        
            <div className={styles.container}>
                <div className={styles.box}>

                    <br />   

                    <p style={{fontSize: '22px', fontWeight: 'normal'}}><Globe2 size={55} style={{marginRight: '10px', color: '#132350'}} /><strong style={{fontSize: '34px', fontWeight: 'bolder'}}>BMA</strong> Portal Vr. 3.0.12</p>

                    <input className={styles.inputs} type="email" name="email" placeholder="Email" 
                           autoComplete="email" value={email} 
                           onChange={({target}) => setEmail(target.value)} required
                    />
    
                    <input className={styles.inputs} type="password" name="senha" placeholder="Senha" 
                           autoComplete="current-password" value={password} 
                           onChange={({target}) => setPassword(target.value)} required
                           onKeyDown={handleKeyDown}
                    />               
            
                    <Button onClick={validarLogin} className={styles.btn}>{!loading ? "ENTRAR" : "Aguarde..."}</Button>     

                    <p style={{fontSize: '16px', fontWeight: 'bolder', fontStyle: 'italic', color: '#191970'}}>Nós acreditamos em nossa parceria.</p> 
 
    
                </div>
            </div>

            <div className={styles.imagem}>
                <img src={imagem2} alt="Imagem" />
            </div>

          </div>
        </form>
    )}

export default Login;